<div class="row">
    <div class="col-lg-12">
        <div class="card">
            <div class="card-body">
                <div class="row" *ngIf="!isModal">
                    <div class="col-md-12 mb-md-0 mb-1">
                        <h4 class="card-title m-0">Change Password</h4>
                    </div>
                </div>
                <hr>
                <div class="basic-form">
                    <form [formGroup]="formData" (ngSubmit)="onSubmit()">
                        <div class="form-row">
                            <div class="form-group" [ngClass]="key ? 'col-md-12' : 'col-md-6'">
                                <label>Old Password</label>
                                <input [type]="oldPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="Old Password..." formControlName="old_password" [ngClass]="{ 'is-invalid': submitted && f.old_password.errors }">
                                <a class="eye-on-off" (click)="toggleOldPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !oldPasswordTextType, 'fa-eye-slash': oldPasswordTextType }"></i></a>
                                <div *ngIf="submitted && f.old_password.errors" class="invalid-feedback">
                                    <div *ngIf="f.old_password.errors.required">The Old Password field is required</div>
                                </div>
                                <div *ngIf="errors?.old_password" class="invalid-feedback apiError">
                                    <div *ngFor="let item of errors?.old_password;trackBy:trackByIndex;">{{item}}</div>
                                </div>
                                <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span>
                            </div>
                            <div class="form-group" [ngClass]="key ? 'col-md-12' : 'col-md-6'">
                                <label>New Password</label>
                                <input [type]="newPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="New Password..." formControlName="new_password" [ngClass]="{ 'is-invalid': submitted && f.new_password.errors }">
                                <a class="eye-on-off" (click)="toggleNewPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !newPasswordTextType, 'fa-eye-slash': newPasswordTextType }"></i></a>
                                <div *ngIf="submitted && f.new_password.errors" class="invalid-feedback">
                                    <div *ngIf="f.new_password.errors.required">The New Password field is required</div>
                                    <div *ngIf="f.new_password.errors.minlength">The New Password field must be at least 6 characters</div>
                                    <div *ngIf="!f.new_password.errors.minlength && f.new_password.errors.pattern">
                                        The New Password must contain at least: 1 uppercase letter, 1 lowercase letter, 1 number
                                    </div>
                                </div>
                                <div *ngIf="errors?.new_password" class="invalid-feedback apiError">
                                    <div *ngFor="let item of errors?.new_password;trackBy:trackByIndex;">{{item}}</div>
                                </div>
                                <span class="fst-normal small text-muted help-text">[A-Z , a-z , 0-9 , @  ] Allowed</span>
                            </div>
                             <div class="form-group" [ngClass]="key ? 'col-md-12' : 'col-md-6'">
                                <label>Confirm Password</label>
                                <input [type]="confirmPasswordTextType ? 'text' : 'password'" class="form-control" placeholder="Confirm Password..." formControlName="new_password_re" [ngClass]="{ 'is-invalid': submitted && f.new_password_re.errors }">
                                <a class="eye-on-off" (click)="toggleConfirmPasswordFieldTextType()"><i class="fa " [ngClass]="{'fa-eye': !confirmPasswordTextType, 'fa-eye-slash': confirmPasswordTextType }"></i></a>
                                <div *ngIf="submitted && f.new_password_re.errors" class="invalid-feedback">
                                    <div *ngIf="f.new_password_re.errors.required">The Confirm Password field is required</div>
                                    <div *ngIf="f.new_password_re.errors.mustMatch">The Confirm Password confirmation does not match</div>
                                </div>
                                <div *ngIf="errors?.new_password_re" class="invalid-feedback apiError">
                                    <div *ngFor="let item of errors?.new_password_re;trackBy:trackByIndex;">{{item}}</div>
                                </div>
                            </div>
                              <div class="form-group" [ngClass]="key ? 'col-md-12' : 'col-md-6'">
                                <label class="d-md-block d-none">&nbsp;</label>                                
                                <button type="submit" [disabled]="isLoad" class="btn btn-theme"> Change Password</button>
                                <!-- <button type="button" class="btn btn-dark">Reset</button> -->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>