import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = '';

  env = environment;

  constructor(
    @Inject(DOCUMENT) public document: Document,
    private titleService: Title
  ) {
    const window: any = this.document.defaultView;
    const host = window.location.hostname;
    this.setDynamicStyle(host);
  }

  async setDynamicStyle(host: any) {
    // console.log(typeof host, host, this.env, this.env.hosts, this.env.host);
    console.log(host);
    // console.log(this.env);
 
    const index = this.env.hosts.findIndex((e: any) => (host === e.dName) || (host === 'www.'+e.dName));
    this.env.host = (index == -1) ? this.env.hosts[0] : this.env.hosts[index];
    if (index == -1) {
      this.env.host.domain = host;
    }
    if (!localStorage.getItem(`${this.env.panel}theme`)) {
      localStorage.setItem(`${this.env.panel}theme`, this.env.host.theme);
    }
    this.titleService.setTitle(`Welcome to ${this.env.host.name == 'localhost' ? host : this.env.host.name}`);

    if (this.env.host.name != 'localhost') {
      this.env.baseUrl = `https://api.${this.env.host.domain}`;
      this.env.domain = this.env.host.domain;
    }
    this.env.guestEnable = this.env.host.guestEnable;

    const head = this.document.getElementsByTagName('head')[0];
    const themePath = this.env.host.themePath;
    themePath.forEach(element => {
      const style = this.document.createElement('link');
      style.rel = 'stylesheet';
      style.href = `${element}`;

      head.appendChild(style);
    });

    this.changeFavicon(`assets/images/${this.env.host.name}/favicon.png`)
    console.log(this.env.host);
  }

  changeFavicon(newFaviconFilename: string): void {
    const head = document.getElementsByTagName('head')[0];

    // Remove existing favicon links
    const existingLinks = head.querySelectorAll('[rel="icon"]');
    existingLinks.forEach(link => head.removeChild(link));

    // Create new favicon links
    const newFaviconLink = document.createElement('link');
    newFaviconLink.rel = 'icon';
    newFaviconLink.type = 'image/png';
    newFaviconLink.href = newFaviconFilename;

    // Append the new link to the head
    head.appendChild(newFaviconLink);
  }

}
